// Normaize everything
@import "css/reset/_normalize.css";

// Theme angular
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";

// @import "~bootstrap/scss/bootstrap-reboot";
// @import "~bootstrap/scss/bootstrap-grid";
// @import "~bootstrap/scss/utilities";

// Utilities
@import "css/utilities/variables";
@import "css/utilities/mixins";
@import "css/utilities/responsive";
@import "css/utilities/tools";
@import "css/utilities/animations";

// Global css
@import "css/main";
@import "css/elements/stepheader";
@import "css/elements/steps";
@import "css/elements/options";
@import "css/elements/notary-pack";
@import "css/elements/stepmoney";
@import "css/elements/tooltip";
@import "css/elements/info-box";
@import "css/elements/service-page";

// Angular personalisation
@import "css/angular-material/dialog";
@import "css/angular-material/button";
@import "css/angular-material/expansionpanel";
@import "css/angular-material/select";
@import "css/angular-material/input";
@import "css/angular-material/buttontoggle";
@import "css/angular-material/menu";
@import "css/angular-material/checkbox";
@import "css/angular-material/radio";
@import "css/angular-material/snackbar";

@import "~ngx-sharebuttons/themes/default/default-theme";
@import "~ngx-sharebuttons/themes/outline/outline-theme";

input.custom-datepicker {
  border: none;
  width: 100%;
}
.redError {
  background: red !important;
  color: white !important;
}
.greenSuccess {
  background-color: green !important;
  color: white !important;
}
simple-snack-bar span {
  margin: auto;
  text-align: center;
}
.notification-btn {
  .mat-badge-content {
    top: 5px !important;
    right: -2px !important;
    background-color: #ff6767;
  }
}
.mat-expansion-panel-content > .mat-expansion-panel-body {
  padding: 0;
}
#send-message {
  .article-content {
    padding-right: 0px;
  }
}

.notification-btn {
  .mat-badge-content {
    top: 5px;
    right: -2px;
  }
  .mat-badge-content {
    background-color: #ff6767;
  }
}
#notifications {
  &:hover,
  &:focus {
    animation: shake-lr 0.7s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  }
  width: 20px;
  filter: invert(71%) sepia(55%) saturate(509%) hue-rotate(39deg) brightness(92%) contrast(85%);
}
