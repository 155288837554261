@import "../../../css/utilities/mixins";

// COLORS
$my-app-accent: (
  50: #f2f6f9,
  100: #dee9ef,
  200: #c8dbe5,
  300: #b2cddb,
  400: #a2c2d3,
  500: #91b7cb,
  600: #89b0c6,
  700: #7ea7be,
  800: #749fb8,
  900: #6290ac,
  A100: #ffffff,
  A200: #e8f6ff,
  A400: #b5e3ff,
  A700: #9cd9ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$my-app-primary: (
  50: #e7e7ed,
  100: #c2c2d1,
  200: #9999b3,
  300: #707094,
  400: #52527d,
  500: #333366,
  600: #2e2e5e,
  700: #272753,
  800: #202049,
  900: #141438,
  A100: #7777ff,
  A200: #4444ff,
  A400: #1111ff,
  A700: #0000f6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

$success: #8EC640;
$success-darken: #77AC2E;
$warning: #ea9c57;
$warning-darken: #d39055;
$info: #5795ea;
$info-darken: #427ccb;
$error: #FF5722;
$error-darken: #D83C0B;

// Export usefull colors
$light-background: #f3f3f3;
$text-color: rgba(0, 0, 0, 0.87);
$extralight-gray: rgba(0, 0, 0, 0.04);
$light-gray: rgba(0, 0, 0, 0.12);
$mid-gray: rgba(0, 0, 0, 0.54);
$overlay: rgba(0, 0, 0, 0.32);

// FONTS
$custom-typography: mat-typography-config(
  $font-family: "Fira Sans"
);

// BORDER RADIUS
$basic-radius: calculateRem(5px);
