@import "../../css/utilities/variables";
@import "../../css/utilities/mixins";
@import "../../css/utilities/responsive";

#notary-pack-container {
  margin: $neg-spacing-xs $neg-spacing-xs $spacing-r $neg-spacing-xs;
  @include bp-medium {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: $neg-spacing-s $neg-spacing-s $spacing-s $neg-spacing-s;
  }
  .services-pack-element {
    header {
      display: flex;
      flex-direction: column;
    }
    .pack-pricing-big {
      font-size: calculateRem(45px);
      line-height: calculateRem(32px);
      color: $primary;
      font-weight: 600;
      margin-top: $spacing-xxs;
      small {
        font-weight: 400;
        font-size: calculateRem(20px);
      }
    }
    .pack-pricing-small {
      font-weight: 400;
      font-size: calculateRem(20px);
      color: $mid-gray;
    }
    .pack-details {
      border-top: $basic-border;
      &:not(:last-of-type) {
        padding: $spacing-r $spacing-m;
      }
      .pack-details-title {
        font-weight: 700;
        margin: 0 0 $spacing-xs;
      }
      .details-dialog-trigger {
        margin: 0.3rem 0 0 $spacing-xs;
        width: calculateRem(18px);
        img {
          height: calculateRem(14px);
        }
      }
    }
  }
}

.services-carte-panel-content {
  .details-dialog-trigger {
    margin: 0.3rem 0 0 $spacing-xs;
    width: calculateRem(18px);
    img {
      height: calculateRem(14px);
    }
  }
}
