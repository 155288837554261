@import "../../css/utilities/mixins";

.mat-checkbox {
  .mat-checkbox-frame {
    border-width: 1px;
  }
  .mat-checkbox-layout {
    white-space: initial;
    .mat-checkbox-label {
      line-height: calculateRem(19px);
    }
  }
}
