@import "../../css/utilities/variables";
@import "../../css/utilities/mixins";
@import "../../css/utilities/responsive";

.free-service {
  &:hover,
  &:focus {
    .tooltip-container {
      display: block;
    }
  }
}

.tooltip-content {
  ul {
    margin: 0;
    padding: 0 0 0 calculateRem(15px);
  }
}
