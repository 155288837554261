@import "../../css/utilities/mixins";
@import "../../css/utilities/variables";
@import "../../css/utilities/responsive";

mat-accordion {
  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: none !important;
  }
  &.login-accordeon {
    .mat-expansion-panel-content > .mat-expansion-panel-body {
      padding: 0 $spacing-m $spacing-m $spacing-m;
    }
    .mat-content {
      overflow: visible;
    }
  }
  &.services-carte-accordeon {
    .mat-expansion-panel.mat-expanded {
      overflow: visible;
    }
    .mat-expansion-indicator {
      position: relative;
      left: -5px;
    }
    .mat-expansion-panel-content > .mat-expansion-panel-body {
      padding: 0 $spacing-xs $spacing-xs $spacing-xs;
      @include bp-small {
        padding: $spacing-xs $spacing-r $spacing-r $spacing-r;
      }
    }
  }
}
