@import "../../css/utilities/variables";

.mat-radio-button {
  .mat-radio-label-content {
    white-space: initial;
    line-height: inherit;
  }
}

// .date-element {
.mat-radio-button.date-element {
  display: block;
  .mat-radio-label {
    width: 100%;
  }
  .mat-radio-label-content {
    flex-grow: 2;
  }
}
// }
