@import "../utilities/mixins";
@import "../utilities/responsive";

.certiorder {
  .step-content {
    background: white;
    padding: $spacing-r;
    border-radius: $basic-radius;
    @include bp-medium {
      padding: $spacing-m;
    }
    > section {
      > h6 {
        app-tooltip {
          margin-left: $spacing-xxs;
        }
        .mandatory {
          color: $warning;
          font-size: calculateRem(12px);
        }
        .non-mandatory {
          display: inline-block;
          color: $mid-gray;
          font-size: calculateRem(12px);
          margin-left: $spacing-xxs;
        }
      }
      &:not(:first-child){
        margin-top: calculateRem(36px);   
      }
    } 
  }
  .step-actions {
    display: flex;
    flex-direction: column;
    margin-top: $spacing-r;
    @include bp-medium {
      flex-direction: row;
      justify-content: space-between;
      margin-top: $spacing-m;
    }
    button + button {
      margin-top: $spacing-r;
      @include bp-medium {
        margin-top: 0;
      }
    }
  }
}
